import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IListado, IChat, IQueryParam } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IChat>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/chat`, { params });
  }

  public listarPorId(id: string): Observable<IChat> {
    return this.http.get(`/chat/${id}`);
  }

  // Método para eliminar un chat por ID
  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/chat/${id}`);
  }
}
