import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IApikey,
  ICreateApikey,
  IListado,
  IQueryParam,
  IUpdateApikey,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class ApikeysService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IApikey>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/apikeys`, { params });
  }

  public crear(dato: ICreateApikey): Observable<IApikey> {
    return this.http.post(`/apikeys`, dato);
  }

  public listarPorId(id: string): Observable<IApikey> {
    return this.http.get(`/apikeys/${id}`);
  }

  public editar(id: string, dato: IUpdateApikey): Observable<IApikey> {
    return this.http.put(`/apikeys/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/apikeys/${id}`);
  }
}
