import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListadoApikeysComponent } from './listado-apikeys/listado-apikeys.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { RoutingModule } from './routing.module';
import { CrearEditarApikeysComponent } from './crear-editar-apikey/crear-editar-apikey.component';

@NgModule({
  declarations: [ListadoApikeysComponent, CrearEditarApikeysComponent],
  imports: [CommonModule, RoutingModule, AuxiliaresModule],
})
export class ApikeysModule {}
