import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IFilter, IQueryParam, IListado, IChat } from 'modelos/src';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { Subscription } from 'rxjs';
import { UsuariosService } from '../../usuarios/usuarios.service';
import { MostrarBodyComponentchat } from './mostrarBody/mostrarBody.component';
import { ChatService } from '../chat.service';
import { ICliente } from 'modelos/src';
import { IUsuario } from 'modelos/src';

interface ILogTabla extends IChat {
  color: string;
}

@Component({
  selector: 'app-listado-chat',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss'],
})
export class ListadoComponentchat implements OnInit {
  public loading = true;

  // TABLA
  public dataSource = new MatTableDataSource<IChat>([]);
  public totalCount = 0;
  // public columnas: string[] = [];
  // public nombreColumnas: string[] = [];
  public columnas: string[] = [
    'fechaCreacion',
    'tipoMensaje',
    'texto',
    'textoOriginal',
    'razonamiento',
    'usuario',
    'cliente',
    'fuenteMensaje',
    'eliminar',
  ];
  public nombreColumnas: string[] = [
    'Fecha',
    'Tipo de Mensaje',
    'Texto',
    'Texto Original',
    'Razonamiento',
    'Usuario',
    'Cliente',
    'Fuente del Mensaje',
    'Eliminar',
  ];
  public saveColumnas = 'admin-gas-chats';

  //####################################################
  // Filtros Tabla

  public tipo_mensaje: IFiltroTabla = {
    elementos: [
      { nombre: 'Usuario', _id: 'Usuario' },
      { nombre: 'Asistente', _id: 'Asistente' },
    ],
    filter: {
      field: 'tipoMensaje',
    },
    label: 'Tipo de mensaje',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  public fuente_mensaje: IFiltroTabla = {
    elementos: [
      { nombre: 'App', _id: 'App' },
      { nombre: 'Web', _id: 'Web' },
      { nombre: 'Whatsapp', _id: 'Whatsapp' },
    ],
    filter: {
      field: 'fuenteMensaje',
    },
    label: 'Fuente del mensaje',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  public usuario: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idUsuario',
      value: '',
    },
    label: 'Usuario',
    selectLabel: 'username',
    selectValue: '_id',
    tipo: 'select',
  };

  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
      value: '',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  //####################################################

  // QUERY

  private populate = [
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'usuario',
      select: 'username',
    },
  ];

  private filter: IFilter<IChat> = {};
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: '-fecha',
    populate: JSON.stringify(this.populate),
    filter: JSON.stringify(this.filter),
  };

  // Listado Continuo
  public datos$?: Subscription;
  public usuarios$?: Subscription;
  public clientes$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: UsuariosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    public chatService: ChatService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter<IChat>) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // Eliminar fila

  public eliminarFila(id: string): void {
    this.chatService.eliminar(id).subscribe({
      next: () => {
        // Si la eliminación es exitosa, elimina la fila en el frontend
        const index = this.dataSource.data.findIndex((row) => row._id === id);

        if (index !== -1) {
          this.dataSource.data.splice(index, 1);
          this.dataSource = new MatTableDataSource<IChat>(this.dataSource.data);
        }
      },
    });
  }

  //Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IChat>>('chats', this.queryParams)
      .subscribe((data) => {
        const logs = data.datos as ILogTabla[];
        this.totalCount = data.totalCount;
        this.dataSource.data = logs;
        console.log(`listado de chats`, data);
      });
    await this.listadosService.getLastValue('chats', this.queryParams);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  private async listarUsuarios(): Promise<void> {
    const query: IQueryParam = {
      select: 'username',
      sort: 'username',
    };
    this.usuarios$?.unsubscribe();
    this.usuarios$ = this.listadosService
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.usuario.elementos = data.datos;
        console.log(`listado de usuarios`, data);
      });
    await this.listadosService.getLastValue('usuarios', query);
  }

  //MODAL
  // Nuevas propiedades para el modal
  public modalTexto: string = '';
  public isModalOpen: boolean = false;

  // Abre el modal para mostrar el texto completo
  public abrirModalTexto(texto: string): void {
    this.modalTexto = texto; // Asignamos el texto completo al modal
    this.isModalOpen = true; // Abrimos el modal
  }

  // Abre el modal para mostrar el texto original completo
  public abrirModalTextoOriginal(textoOriginal: string): void {
    this.modalTexto = textoOriginal; // Asignamos el texto original al modal
    this.isModalOpen = true; // Abrimos el modal
  }

  // Abre el modal para mostrar el razonamiento completo
  public abrirModalRazonamiento(razonamiento: string): void {
    this.modalTexto = razonamiento; // Asignamos el razonamiento al modal
    this.isModalOpen = true; // Abrimos el modal
  }

  // Cerrar el modal
  public cerrarModal(): void {
    this.isModalOpen = false; // Cerramos el modal
  }
  //Body

  public async mostrarBody(data: IChat) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      // disableClose: true,
    };
    this.matDialog.open(MostrarBodyComponentchat, config);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([
      this.listar(),
      this.listarClientes(),
      this.listarUsuarios(),
    ]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
    this.usuarios$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }

  // Colores by Chat-GPT
  public getRandomColorFromString(str: string): string {
    let hash = 0;
    str = str + 'random2077';
    // Generate a hash code from the string
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert the hash code to a hex color code
    const hex = (hash & 0x00ffffff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - hex.length) + hex;
  }
}
