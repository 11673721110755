<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar> 

<div class="mat-elevation-z8" style="margin: 1em">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [datos]="[usuario, cliente, tipo_mensaje, fuente_mensaje]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
  </div>

  <!-- Tabla -->
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna fecha --> 
    <ng-container matColumnDef="fechaCreacion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fecha
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.fechaCreacion | date : "short" }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna tipo de mensaje -->
    <ng-container matColumnDef="tipoMensaje">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Tipo de mensaje
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.tipoMensaje }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna texto -->
<ng-container matColumnDef="texto">
  <mat-header-cell *matHeaderCellDef mat-sort-header> Texto </mat-header-cell>
  <mat-cell *matCellDef="let row">
    <p [style.color]="row.color" (click)="abrirModalTexto(row.texto)">
      {{ row.texto | slice:0:50 }}... <!-- Limitar el texto a 50 caracteres -->
    </p>
  </mat-cell>
</ng-container>

<!-- Columna texto original -->
<ng-container matColumnDef="textoOriginal">
  <mat-header-cell *matHeaderCellDef mat-sort-header> Texto Original </mat-header-cell>
  <mat-cell *matCellDef="let row">
    <p (click)="abrirModalTextoOriginal(row.textoOriginal)">
      {{ row.textoOriginal | slice:0:50 }}... <!-- Limitar el texto a 50 caracteres -->
    </p>
  </mat-cell>
</ng-container>

<!-- Columna razonamiento -->
<ng-container matColumnDef="razonamiento">
  <mat-header-cell *matHeaderCellDef mat-sort-header> Código de Respuesta </mat-header-cell>
  <mat-cell *matCellDef="let row">
    <p (click)="abrirModalRazonamiento(row.razonamiento)">
      {{ row.razonamiento | slice:0:50 }}... <!-- Limitar el texto a 50 caracteres -->
    </p>
  </mat-cell>
</ng-container>

    <!-- Columna usuario-->
    <ng-container matColumnDef="usuario">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Usuario
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <p>
            {{ row.usuario?.username }}
          </p>
        </mat-cell>
      </ng-container>

    <!-- Columna cliente-->
    <ng-container matColumnDef="cliente">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Cliente
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <p>
            {{ row.cliente?.nombre }} 
          </p>
        </mat-cell>
      </ng-container>

    <!-- Columna fuente del mensaje-->
    <ng-container matColumnDef="fuenteMensaje">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Fuente del Mensaje
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <p>
            {{ row.fuenteMensaje }}
          </p>
        </mat-cell>
      </ng-container>

    <!-- Columna eliminar -->
    <ng-container matColumnDef="eliminar">
      <mat-header-cell *matHeaderCellDef> Eliminar </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button
          mat-raised-button
          color="primary"
          (click)="eliminarFila(row._id)"
        >
          Eliminar
        </button>
      </mat-cell>
    </ng-container>

    <!-- Columna body -->
    <ng-container matColumnDef="body">
      <mat-header-cell *matHeaderCellDef> Body </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div fxLayout="row" fxLayoutGap="10px">
          <button
            *ngIf="row.body"
            mat-raised-button
            (click)="mostrarBody(row.body)"
            color="accent"
          >
            Req
          </button>
          <button
            *ngIf="row.respuesta"
            mat-raised-button
            (click)="mostrarBody(row.respuesta)"
            color="accent"
          >
            Res
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>

  <!-- Paginator -->
  <mat-paginator
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator>

  <!-- Modal Component -->
<app-ventanita 
[text]="modalTexto" 
[isOpen]="isModalOpen" 
(close)="cerrarModal()">
</app-ventanita>
</div>


