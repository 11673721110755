import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ChatRoutingModule } from './chat.routing.module';
import { ListadoComponentchat } from './listado/listado.component';
import { MostrarBodyComponentchat } from './listado/mostrarBody/mostrarBody.component';
import { ModalComponent } from 'src/app/auxiliares/ventanita/ventanita.component';
@NgModule({
  declarations: [
    ListadoComponentchat,
    MostrarBodyComponentchat,
    ModalComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, ChatRoutingModule],
})
export class ChatModule {}
