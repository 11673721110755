import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IApikey,
  ICliente,
  ICreateApikey,
  ICreateDispositivo,
  IDeviceProfileChirpstack,
  IDispositivo,
  IListado,
  ILoraServer,
  ILoteDispositivo,
  IQueryParam,
  ITipoDispositivo,
  IUpdateApikey,
  IUpdateDispositivo,
  TipoConectividad,
  TipoDispositivo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TextareaComponent } from 'src/app/auxiliares/textarea/textarea/textarea.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { ApikeysService } from '../service';

@Component({
  selector: 'app-crear-editar-apikey',
  templateUrl: './crear-editar-apikey.component.html',
  styleUrls: ['./crear-editar-apikey.component.scss'],
})
export class CrearEditarApikeysComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;
  public hide = true;

  public clientes: ICliente[] = [];

  // Listado Continuo
  public clientes$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IApikey,
    private dialogRef: MatDialogRef<CrearEditarApikeysComponent>,
    private service: ApikeysService,
    private helper: HelperService,
    private listadosService: ListadosService
  ) {}

  //

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Apikey' : 'Crear Apikey';

    this.form = new FormGroup({
      identificacion: new FormControl(this.data?.identificacion, [
        Validators.required,
      ]),
      idCliente: new FormControl(this.data?.idCliente, [Validators.required]),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //
  private getData() {
    const data: ICreateApikey | IUpdateApikey = this.form?.value;

    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      if (this.data?._id) {
        // Update
        const data = this.getData();
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Apikey actualizada');
        this.dialogRef.close();
      } else {
        // Create
        const data = this.getData();
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Apikey creada');
        this.dialogRef.close();
      }
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  // Listados

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    await Promise.all([this.listarClientes()]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
  }
}
